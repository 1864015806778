
import { defineComponent, computed, ref } from 'vue'
export default defineComponent({
  props: {
    tracing: {
      type: Object
    }
  },
  setup (props) {
    // console.log('tracing', props.tracing)
    const tracingData = computed(() => props.tracing)

    const formatDate = (d: string) => {
      const dt = new Date(d)
      return dt.getDay().toString().padStart(2, '0') + '/' + (dt.getMonth() + 1).toString().padStart(2, '0') + '/' + dt.getFullYear()
    }

    const getWarmupDate = (complementarySchedules: Array<any>) => {
      const warmUpDate = ref<Date | null>()
      complementarySchedules.forEach((cs: any) => {
        if (cs.title === 'WarmUp') {
          warmUpDate.value = new Date(cs.to)
        }
      })
      return new Date(warmUpDate.value as Date)
    }

    return {
      tracingData,
      formatDate,
      getWarmupDate
    }
  }
})
