
import { StaffTracingAPI } from '@/api/staffTracing.api'
import { StaffTracing } from '@/models/tracing-staff/classes/StaffTracing'
import { injectStrict } from '@/utils/injectStrict'
import { useToast } from 'primevue/usetoast'
import { defineComponent, onMounted, ref } from 'vue'
import { PersonKey } from '../keys'
import Activity from '../components/Activity.vue'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
export default defineComponent({
  components: {
    Activity
  },
  setup () {
    const loading = ref(false)
    const person = injectStrict(PersonKey)
    const teacherActivity = ref<StaffTracing[]>()
    // const tracingReduced = ref<any[]>()
    const kickOffDate = ref<Date | null>(null)
    // const warmUpDate = ref<Date | null>(null)
    const disabledKO = ref<boolean>(false)

    const toast = useToast()

    // const groupRecords = (tracing: Array<StaffTracing>) => {
    //   const tracingReduced = tracing.reduce(function (map, obj) {
    //     const level = map[obj.levelInfo.aliasTag] = map[obj.levelInfo.aliasTag] || {}
    //     level[obj.camadaInfo.id] = level[obj.camadaInfo.id] || { tracing: obj }
    //     return map
    //   }, Object.create(null))
    //   return tracingReduced
    // }

    const dateFormat = (date: any) => {
      if (date == null) return null
      let formatedDate = null
      if (date !== null) {
        formatedDate = new Date(date).toLocaleDateString('es-AR')
      }
      const dateParts = formatedDate!.split('/')
      const dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
      return new Date(dateObject)
    }

    const changeKickOff = async () => {
      if (teacherActivity.value) {
        const data = teacherActivity.value[0]
        try {
          await StaffTracingAPI.updateTracing({ ...data, kickOffDate: kickOffDate.value as Date })
          toast.add({
            summary: ToastSummaries.Success,
            detail: 'KickOff actualizado.',
            severity: ToastSeverities.Success,
            life: ToastLife.Default
          })
        } catch (error) {
          toast.add({
            summary: ToastSummaries.Error,
            detail: 'Error al actualizar, intente nuevamente.',
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        }
      }
    }

    const loadTracing = async () => {
      try {
        loading.value = true
        teacherActivity.value = await StaffTracingAPI.getTeacherActivity(person.value?.id as string)
        // tracingReduced.value = groupRecords(teacherActivity.value)
        kickOffDate.value = dateFormat(teacherActivity.value[0]?.kickOffDate as Date)
        disabledKO.value = kickOffDate.value !== null
      } catch (err) {
        toast.add({ severity: 'error', summary: 'Error al obtener los registros', detail: String(err), life: 3000 })
      } finally {
        loading.value = false
      }
    }
    onMounted(async () => {
      loadTracing()
    })

    return {
      loading,
      teacherActivity,
      kickOffDate,
      disabledKO,
      changeKickOff
    }
  }
})
